.BloxAdminRow {
  margin-left: 0;
  margin-right: 0;
  height: 100%;
  background-color: $white;

  h3 {
    max-width: 900px;
    margin: 3rem auto 2rem auto;
    color: $white;
    font-weight: 200;

    @include media-breakpoint-down(sm) {
      margin-top: 3rem;
    }

    span {
      float: right;
      text-transform: uppercase;
      font-weight: 300;
      font-size: 1.25rem;
      padding-top: 0.5rem;

      @include media-breakpoint-down(sm) {
        float: none;
        display: block;
      }
    }
  }

  p.adminPageDescription {
    max-width: 900px;
    margin: -0.5rem auto 1rem auto;
    padding-right: 4rem;
    color: $grey_light;
    font-weight: normal;
  }

  .BloxAdminContentContainer {
    max-width: 900px;
    margin: 0 auto 2rem auto;
    background-color: $white;
    padding: 1rem;
    border-radius: 5px;

    &.noPadding {
      padding: 0;
    }

    form {
      margin-top: 0;
    }
  }
}

// .BloxAdminMenuPanel {
//   background-color: $grey_dark;
//   width: 100%;
//   height: 100vh !important;
//   padding-top: 2rem;
//   padding-bottom: 2rem;
//   overflow-x: scroll;
//   display: none;
//
//   h5 {
//     color: $white;
//     margin-bottom: .5rem;
//     text-transform: uppercase;
//     font-weight: 600;
//   }
//
//   ul.nav {
//     margin: 0 0 2rem 0 !important;
//     padding: 0;
//
//     li {
//       margin: 0;
//       padding: 0;
//       line-height: 1;
//
//       a {
//         color: $grey_light;
//         text-decoration: none;
//         margin: 0;
//         padding: .25rem 0 .25rem .75rem;
//         line-height: 2;
//
//         svg {
//           margin-right: .5rem;
//           font-size: 1.5rem;
//           margin-top: -6px;
//         }
//
//         &:hover {
//           color: $white;
//         }
//       }
//     }
//   }
// }

.BloxAdminContent {
  padding-top: 2rem;
  // overflow-x: scroll;
  background-color: $grey_dark;
}

.BloxAdminContent-white {
  padding-top: 2rem;
  // overflow-x: scroll;
  background-color: $white;
}

.BloxAdminAnalyticsRow {
  background-color: $grey_medium_dark;
  margin-left: 0;
  margin-right: 0;
  padding: 1rem;
  border-top-right-radius: 5px;
  border-top-left-radius: 5px;

  h6 {
    color: $white;
    text-align: center;
    margin-top: 0.5rem;
    font-weight: 300;
    text-transform: uppercase;
  }
}

.BloxAdminGuestlistContainer {
  position: relative;
}

.guestlistButtonGroupContainer {
  margin: 0 0 1rem 0;
}

.buttonGuestlist {
  background-color: white !important;
  color: gray !important;
  border: solid 1px gray !important;
  border-radius: 0px !important;
  padding-top: 0 !important;
  padding-bottom: 0 !important;
  padding-right: 30px !important;
  padding-left: 30px !important;

  &-selected {
    padding-right: 30px !important;
    padding-left: 30px !important;
    padding-top: 0 !important;
    padding-bottom: 0 !important;
    background-color: white !important;
    color: black !important;
    border: solid 1px gray !important;
    border-radius: 0px !important;
    border-bottom: solid 1px white !important;
    @media (max-width: 768px) {
      width: 115px;
      padding-right: 3px !important;
      padding-left: 3px !important;
      font-size: smaller !important;
      text-transform: none !important;
    }
  }

  @media (max-width: 768px) {
    width: 115px;
    padding-right: 3px !important;
    padding-left: 3px !important;
    font-size: smaller !important;
    text-transform: none !important;
  }
}

.buttonGuestlist-dummy {
  padding-right: 30px !important;
  padding-left: 30px !important;
  padding-top: 0 !important;
  padding-bottom: 0 !important;
  background-color: white !important;
  color: rgb(255, 255, 255) !important;
  border-bottom: solid 2px gray !important;
  border-radius: 0px !important;

  @media (max-width: 768px) {
    width: 10px;
    padding-right: 1px !important;
    padding-left: 1px !important;
    min-width: 0px !important;
  }
}

.guestlistTicketCount {
  position: absolute;
  top: 1rem;
  right: 1rem;
  font-size: 1.5rem;
  color: $grey_medium;

  span {
    display: none;
    font-size: 1rem;
    text-transform: uppercase;
  }
}

.BloxEventGuestListTable {
  td.guestListCheckIn {
    max-width: 350px;
    padding-right: 0.25rem !important;
    button {
      position: relative;
      margin-bottom: 0.25rem;
      margin-left: 0.5rem;
      padding: 2px 10px 2px 12px;
      background-color: $green;
      border-color: $green;

      svg {
        position: relative;
        top: 0;
        left: -4px;
        height: 32px;
        width: 32px;
      }

      span {
        position: absolute;
        display: block;
        bottom: 30%;
        right: -8px;
        font-size: 0.7rem;
        font-weight: bold;
        height: 16px;
        width: 16px;
        background-color: $grey_ultra_light;
        color: $grey_medium_dark;
        padding: 0;
        margin: 0;
        border-radius: 16px;
      }

      &.disabled {
        opacity: 0.25;
        background-color: $grey_dark !important;
        border-color: $grey_dark !important;

        &:hover {
          cursor: grab;
          background-color: $grey_dark !important;
        }

        svg {
          color: $grey_medium;
        }
      }
    }
  }
}

.guestlistFilterForm {
  background-color: $white;
  padding: 0;
}

.adminTicketInventoryTable {
  td {
    font-weight: normal;
    text-transform: none;
  }

  td.name {
    font-weight: bold;
    text-transform: uppercase;
  }
}

.BloxAdminRow {
  .btn-primary {
    background-color: $blue !important;
    border-color: $blue !important;
  }

  .btn-secondary {
    background-color: #505c5b !important;
    border-color: #505c5b !important;
  }
}

.BloxAdminEventDetails {
  .Header {
    background-color: $grey_dark;
    margin-top: 1rem;
    margin-bottom: 1rem;
    padding: 5px;
    margin-bottom: 0.5rem;
    border-top-right-radius: 5px;
    border-top-left-radius: 5px;
  }
}

.link-upcomming-events {
  color: rgba(186, 201, 200, 0.75);
  text-decoration: none !important;
  font-weight: 200;
  line-height: 1.2;
  font-family: "Titillium Web", sans-serif;
  font-size: 18px;
}

.BloxAdminEventDetailsHeader {
  height: 400px;
  margin: 0 !important;
  background-size: contain;
  background-repeat: repeat;
  position: relative;

  @include media-breakpoint-down(sm) {
    height: auto;
  }

  .leftColumn {
    height: 400px;
    padding: 0;
    background-color: $black;
  }

  div.img {
    display: block;
    width: 100%;
    height: 400px;
    background-size: auto 400px;
    background-repeat: no-repeat;
    background-position: center;
    background-color: $black;
    border-left: 1rem solid black;
    border-right: 1rem solid black;
  }

  h4 {
    background-color: rgba(0, 0, 0, 0.9);
    -webkit-backdrop-filter: blur(10px);
    backdrop-filter: blur(10px);
    color: $grey_very_light;
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    margin: 0;
    padding: 0.5rem 1rem;
    font-size: 1.25rem;
    text-transform: uppercase;
    font-weight: bold;
    overflow-x: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;

    span {
      display: block;
      font-size: 0.8rem;
      color: $grey_medium;
      text-transform: none;
      font-weight: 200;
      line-height: 1rem;

      strong {
        text-transform: uppercase;
        font-weight: bold;
      }

      &.date {
      }
    }
  }

  .rightColumn {
    background-color: $grey_dark;
    -webkit-backdrop-filter: blur(5px);
    backdrop-filter: blur(5px);
    width: 100%;
    position: relative;
    padding: 0;

    .metrics {
      padding: 0 1rem;
    }

    .buttonLink {
      padding-top: 1rem;
    }
  }

  .googleMapContainer {
    background-color: $grey_dark;
    height: 120px;
    width: 100%;
    overflow: hidden;
    opacity: 1;
    margin-top: 1rem;

    @include media-breakpoint-down(sm) {
      position: relative;
      width: auto;
      top: 0;
      left: 0;
      border-top-right-radius: 0;
    }
  }
}

.metrics {
  padding: 0 1rem;
}

.buttonLink {
  padding-top: 1rem;
}

.metric {
  font-weight: bold;
  padding: 0.5rem;
  font-size: 1.25rem;
  padding-right: 1rem;
  line-height: 1rem;
  text-align: right;
  color: $grey_very_light;
  position: relative;
  z-index: 50;
  background-color: $grey_dark;

  span.overline,
  span.underline {
    display: block;
    font-weight: 400;
    font-size: 0.7rem;
    text-transform: uppercase;
    color: $grey_very_light;

    &.overline {
      font-weight: 200;
      color: $grey_medium_dark;
      margin-bottom: 0.25rem;
    }
  }

  svg {
    height: 32px;
    width: 32px;
    float: right;
    display: block;
    margin-left: 0.8rem;
    color: $grey_medium_dark;
    position: relative;
    top: -2px;
    fill: $grey_medium_dark;
  }
}

.clientOnboardingContainer {
  h4 {
    margin-top: 0 !important;
  }

  .clientOnboardingSignInSignUpButtons {
    margin: 0 auto;
    width: 160px;
    button {
      float: left;
      margin: 0 10px;
    }
  }
}

//Admin users related list
.table-header {
  color: rgb(153, 171, 170);
  margin: 0;
  font-size: 11px;
  font-weight: bolder;
}

.user-table-row {
  border-bottom: 1px solid #d7d7d7;
  padding-bottom: 5px;
  padding-top: 5px;
}

.user-table-header-row {
  border-bottom: 1px solid #d7d7d7;
  padding-bottom: 5px;
}
