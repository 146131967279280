.BloxEventPurchase {
  padding: 1rem;



  @media(min-width: 768px) {
    .table-responsive {
      max-height: 25vh;
      overflow-y: auto;
      margin-bottom: 8px;
    }
  }



  @include media-breakpoint-up(md) {
    box-shadow: -2px -2px 10px rgba(0,0,0,.075);
    // border-top: 1px solid $grey_medium;
    position: fixed;
    width: 50%;
    background-color: $white;
    bottom: 0;
    background-color: rgba(255,255,255,1);
    -webkit-backdrop-filter: blur(10px);
    backdrop-filter: blur(10px);
    transition: transform .3s ease-in-out;
    overflow: hidden;
    height:auto;
  }
}

.eventNotOnSale {
  text-align: center;
  font-weight: bold;
  color: $grey_medium;
}

.eventUnavailable {
  font-weight: bold !important;
  text-align: right !important;
  font-size: 1rem !important;
  margin-right: 1.4rem !important;
  line-height: 1.5rem !important;
  text-transform: uppercase !important;

  &.eventSoldOut {
    color: $red !important;
  }
}


.BloxEventPurchaseTable,
.BloxEventDetailsPurchaseTable {

  th.quantity {
    padding-right: 1.4rem !important;
  }

  td.quantity {
    min-width: 110px;
  }

  input {
    margin: 0 .25rem !important;
  }

  i.material-icons {
    margin: 0;

    &:hover {
      cursor: pointer;
      transition: .4s all;
    }
  }

  tbody {
    border-top: 0 !important;
  }

  td.inventoryDetails {
    border-top: 0;
    padding-top: 0;
    padding-bottom: .5rem;
  }

  td {
    padding-bottom: 0;
  }

  .isInSaleWindow-false,
  .isSoldOut-true {
    opacity: .25 !important;
  }

  .isSecret-true {
    display: none;

    &.isSecretShow {
      display: table-row-group !important;
    }
  }
}

.BloxEventPurchaseFormRow,
.BloxEventPurchaseReceiptRow {
  padding: 0;
  margin-left: 0;
  margin-right: 0;
}

.BloxEventPurchaseReceiptRow {
  @include media-breakpoint-down(md) {
    padding-top: 0 !important;
  }
}

.EventPurchaseTableRowPromoCode {
  td {
    border-bottom-color: $grey_ultra_light !important;
  }
  span {
    display: block;
    color: $grey_medium;
    font-size: .8rem;
    line-height: 1rem;
    text-transform: none;
    font-weight: 300;

    p {
      margin-bottom: .5rem;
    }
  }
}


.paymentTypeRow {
  transition: all .3s;
  &.show {
    display: block;
  }

  &.hide {
    display: none;
  }
}


.previousPaymentMethod {
  text-align: center;
  height: 4.7rem;
  height: 3.2rem;
  margin-top: 1rem;
  padding-top: .4rem;
  margin-bottom: 1rem;
  background-color: $white;
  border-radius: 5px;

  svg {
    margin-right: .5rem;
    font-size: 2.5rem;
    color: $green;
  }

  span {
    color: $grey_medium_dark;
  }
}