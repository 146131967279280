.BloxContentRow {
  // margin-left: -1rem;
  // margin-right: -1rem;
  background-color: $red_ultra_dark;
  min-height: 100vh;

  h3 {
    max-width: 900px;
    margin: 2rem auto 1rem auto;
    color: $white;
    font-weight: 200;
    padding: 0 1rem;

    &.fullWidth {
      max-width: none;
    }

    @include media-breakpoint-down(sm) {
      margin-top: 3rem;
    }

    a {
      float: right;
      margin-left: 0.5rem;

      svg {
        height: 24px;
        width: 24px;
      }
    }

    span.whiteLabelType {
      display: inline;
      font-size: 1.25rem;
      text-transform: uppercase;
      color: $grey_base;
      font-weight: 400;
      margin-left: 0.5rem;
    }

    div {
      display: inline;
    }
  }

  p.contentPageDescription {
    max-width: 900px;
    margin: -0.5rem auto 1rem auto;
    padding: 0 1rem;
    padding-right: 4rem;
    color: $grey_light;
    font-weight: normal;
  }

  .contentPagePrimaryImage {
    width: 100%;
    min-height: 300px;
    max-height: 500px;
    background-position: center;
    background-size: cover;
    border-radius: 5px;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.5);
    margin-bottom: 4rem;
  }

  .contentGoogleMapContainer {
    margin-bottom: 1rem;
  }

  .contentAddress {
    margin-bottom: 3rem;
  }

  .BloxContentContainer {
    max-width: 900px;
    margin: 0 auto 2rem auto;
    color: $grey_ultra_light;
    font-weight: 200;
    padding: 0 1rem;
    border-radius: 5px;

    &.fullWidth {
      max-width: none;
    }

    form {
      width: 100%;
      margin-top: 0;
    }
  }

  .BloxContentInner {
    width: 100%;
  }

  .tagList {
    margin: 1rem 0;
    padding: 0;

    li {
      margin: 0 0.5rem 0 0;
      padding: 0 1rem;
      display: inline-block;
      background-color: $grey_base;
      color: $white;
      font-weight: 300;
      font-size: 0.8rem;
      text-transform: uppercase;
      border-radius: 1rem;
    }
  }

  .residentArtistContainer {
    width: 100%;
    margin-bottom: 4rem;
    padding: 0 0.5rem;

    h4 {
      padding: 0 0.5rem;
      color: $grey_medium;
      text-transform: uppercase;
      font-weight: 200;
    }
  }
}

.feedContainer {
  padding: 0 1rem;
  width: 100%;
  margin-bottom: 4rem;

  .feedHeder {
    display: flex;
    justify-content: space-between;
  }
  .tenseSelector {
    transition: all .3s;
    color: rgba(186, 201, 200, 0.75);
    text-decoration: none !important;
    font-weight: 200;
    line-height: 1.2;
    font-family: "Titillium Web", sans-serif;
    font-size: 18px;
    cursor: pointer;
  }
  .tenseSelector:hover {
    transition: all .3s;
    color: rgba(122, 133, 132, 0.75);
  }
  h4 {
    color: $grey_very_light;
    text-transform: none;
    font-weight: 200;
    margin-bottom: 0;
    font-size: 2rem;
  }

  h5 {
    color: $grey_light;
    text-transform: none;
    font-weight: 200;
    font-size: 1.5rem;
    margin: 0 0 2rem 0;
    text-align: right;
  }

  .feedMonthContainer {
    margin-bottom: 4rem;
  }

  .feedDateContainer {
    .feedDate {
      font-size: 1.5rem;
      font-weight: 200;

      @include media-breakpoint-down(sm) {
        font-size: 0.8rem;
        // margin-bottom: .5rem;
        // margin-left: 0;
        // text-align: right;
      }
    }

    .feedDay {
      font-size: large;

      @include media-breakpoint-down(sm){
        font-size: 0.7rem;
      }
    }

    .feedItems {
      .feedItem {
        background-color: $grey_very_dark;
        margin-bottom: 0.5rem;

        .feedItemImage {
          width: 210px;
          min-height: 210px;
          background-repeat: no-repeat;
          background-position: center;
          background-size: contain;
          margin-right: 1rem;
          background-color: $black;
          background-origin: content-box;
          border: 0.5rem solid $black;

          @include media-breakpoint-down(sm) {
            width: 100px;
            min-height: 100px;
          }
        }

        .feedItemDetails {
          padding: 0.5rem 2rem 0.5rem 1rem;
          font-size: 0.8rem;
          color: $grey_medium;

          .feedItemType {
            transform: rotate(90deg);
            background-color: $grey_medium_dark;
            position: absolute;
            top: 0.8rem;
            right: 0;
            text-transform: uppercase;
            color: $white;
            padding: 0.25rem 0.5rem;
            font-weight: bold;
            box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.75);
          }

          h4 {
            margin: 0 0 0.25rem 0;
            padding: 0;
            font-size: 1rem;
            line-height: 1rem;
            color: $grey_very_light;
            font-weight: bold;

            a {
              text-decoration: none;
              color: $grey_very_light;
              transition: 0.3s all;

              &:hover {
                color: $white;
              }
            }
          }

          h6 {
            margin: 0;
            padding: 0;
            font-size: 0.8rem;
            line-height: 1rem;
            text-transform: uppercase;
            color: $grey_medium;
          }
        }

        .soundCloudPlayer {
          @include media-breakpoint-down(sm) {
            display: none;
          }
        }

        .soundCloudLink {
          @include media-breakpoint-up(md) {
            display: none;
          }
        }
      }
    }
  }
}
