.calendarContainer {
  padding: 0 1rem;
  min-height: 76vh;
  position: static !important;

  .rbc-btn-group {
    margin-top: .5rem;
    @include make-col-ready()
    @include make-col(3, 12)

    button {
      transition: .3s all;
      background-color: $grey_very_dark;
      color: $grey_medium;
      border-color: $grey_very_dark;
      font-size: .8rem;
      text-transform: uppercase;

      &:hover {
        border-color: $grey_medium_dark;
        background-color: $grey_medium_dark;
        color: $grey_ultra_light;
      }
    }
  }

  .rbc-toolbar {
    @include make-row();

    .rbc-toolbar-label {
      padding: 0;
      font-size: 2rem;
      font-weight: light;
      text-transform: uppercase;
      color: $grey_medium;
    }
  }

  .rbc-header {
    color: $grey_medium;
    text-transform: uppercase;
    border-color: $black !important;
  }

  .rbc-month-row {
    border-color: $black !important;
  }

  .rbc-month-view {
    border: none;
    position: static !important;
  }

  .rbc-today {
    background-color: $grey_medium;
  }

  .rbc-day-bg {
    border-color: $black !important;
  }

  .rbc-off-range-bg {
    background-color: $grey_very_dark;
  }

  .rbc-event {
    background-color: $black;
    border-top-right-radius: 0;
    border-top-left-radius: 0;
    padding: .5rem .25rem;
  }

  .rbc-event-content {
    overflow: visible !important;
  }

  .rbc-row-bg {
    overflow: visible !important;
  }

  .rbc-month-row {
    overflow: visible !important;
  }
}


.eventCalendarEvent {
  position: relative;
  line-height: 1rem;

  > p {
    margin: 0 0 .1rem 0;
    text-transform: uppercase;
    font-weight: bold;
    font-size: 1rem;
    padding: 0 .25rem;
    opacity: 1;
    width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;

    &:hover {
      opacity: 0;
    }
  }

  > span {
    display: block;
    font-size: .75rem;
    padding: 0 .25rem;
    color: $grey_medium;
    opacity: 1;
    font-weight: 300;

    &:hover {
      opacity: 0;
    }
  }

  .EventTile {
    min-width: 0;
    width: 105%;
    opacity: 0;
    position: absolute;
    bottom: -6px;
    left: -6px;

    .cardImage {
      height: 0;
    }

    .card-footer {
      transition: .3s all;
      opacity: 1;
    }
  }

  &:hover {
    .EventTile {
      // width: 125%;
      opacity: 1;
      // left: -10%;

      .cardImage {
        height: 175px;
      }

      .card-footer {
        opacity: 1;
      }
    }
  }
}
