// Set site typography
@import url('https://fonts.googleapis.com/css?family=Titillium+Web:200,300,400,600');
$font-family-sans-serif:  'Titillium Web', sans-serif;
$font-family-base:        $font-family-sans-serif;
$font-family-display:     $font-family-sans-serif;

// Set site color variables
$black:                   #000;
$white:                   #fff;
$grey_very_dark:          rgb(31, 34, 34);
$grey_dark:               #2C2F2F;
$grey_base:               #585f5f;
$grey_medium_dark:        #7F908F;
$grey_medium:             #99abaa;
$grey_light:              #BAC9C8;
$grey_very_light:         #E7EEF0;
$grey_extra_light:        #F0F5F6;
$grey_ultra_light:        #F6FAFA;
$orange:                  #f16834;
$blue:                    #0691A4;
$green:                   #47AF0B;
$red:                     #CC081B;
$red_ultra_dark:          #9E1414;
