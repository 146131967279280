@import 'bootstrap/scss/bootstrap.scss';
@import './vars';
@import './Lib/all';
@import './Components/all';


body {
  font-family: $font-family-sans-serif;

  &.eventsCarouselView {
    background-color: $black;
  }
}

@media print {
   .noPrint { display: none }
   .BloxAdminContent {
     overflow: visible;
   }

   .form-group.search {
     display: none;
   }
}

.container-fluid {
}

.App {
  height: 98.5vh;
  padding-left: 0rem;
  padding-right: 0rem;
  // overflow-x: hidden;

  &.noScroll {
    overflow: hidden;
  }
}

.clearHeader {
  padding-top: 3rem !important;
}

.clearHeaderPlus {
  padding-top: 5.5rem !important;
}

.clearHeaderAndButtonGroup {
  padding-top: 8.85rem !important;
}

.appState {
  padding: 1rem 0;
  margin-top: 1rem;
  background-color: $red_ultra_dark;
  display: none;
}

h4.Header {
  font-weight: bold;
  text-transform: uppercase;
  color: $white;
  background-color: $grey_dark;
  padding: 1rem .5rem;
  margin: 2rem 0 1rem 0;

  &.noTopMargin {
    margin-top: 0 !important;
  }

  &.clearHeader {
    padding-top: 1rem !important;

    @include media-breakpoint-up(md) {
      margin-top: 3.75rem;
      padding-top: 1rem !important;
    }
  }

  span {
    text-transform: none;
    // font-style: italic;
    float: right;
    font-size: 1.25rem;
    padding-top: .25rem;
    color: $grey_light;
  }

  &.addPadding {
    padding-right: 2rem;
    padding-left: 2rem;
  }

  &.addMargins {
    margin-right: 1rem;
    margin-left: 1rem;
  }
}

a {
  color: #007bff;
  transition: all .3s;

  &:hover {
    color: $grey_base;
    text-decoration: underline;
  }

  &.btn {
    text-decoration: none;
  }
}

.galleryFrame
{
  box-sizing: border-box;
  display: inline-block;
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  padding-top: 46px;
}
